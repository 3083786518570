<template>
<div id="EdmUpgradeVip">  
  <Header/>
  <CommonAlert1     ref="commonAlert1" :title="title" :content="content" :btnnameup="btnNameUp" :onfuncup="onFuncUp"/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png" > 
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4">

        </a> 
      </div>
    </nav>   
    <div class="nicolespace"></div>

    <div class="bg" >
      <img class="bottom_img" v-real-img="pic" src="assets/img/noback.png" id="test" @click="onBanner()">
    </div>

  </body>
</div>
</template>

<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import CommonAlert1 from '@/components/CommonAlert1.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
import { config } from "@/include/config"; 


export default {
  name: 'EdmUpgradeVip',
  components: {
      Header,
      CommonAlert1,
      CommonAlert2Func,
  },
  data () {
    return { 
      // api setting
      setDebugMode:true ,
      apiDebug: false,
      apiName: "",

      // data
        pic: "assets/img/noback.png", 
        ulid: "", 
        activity_list: [],
        edmList: [],
        alid: "",

      // Status
        isOld: false,
        isCanClick: false,
        isVisitor: false,


      // Alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,
    }
  },
  methods: {
    openLogin(){
      this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"Home", type:""}), this.openLoginCallback);
      this.backUrl = "";
    },
    openLoginCallback(data){

    },
    orient(){
      if (this.isOld) {
        this.getOldEdm();
      } else {
        this.getNewEdm();
      }
    },
    getOldEdm(){
      var $img = $("#test");
      if ( $img.prop("width") <= 280 ){
        this.pic = "assets/img/edm_280.jpg";
      } else if ( $img.prop("width") <= 320 ){
        this.pic = "assets/img/edm_320.jpg";
      } else if ( $img.prop("width") <= 360 ){
        this.pic = "assets/img/edm_360.jpg";
      }  else if ( $img.prop("width") <= 375 ){
        this.pic = "assets/img/edm_375.jpg";
      } else if ( $img.prop("width") <= 411 ){
        this.pic = "assets/img/edm_414.jpg";
      } else if ( $img.prop("width") <= 414 ){
        this.pic = "assets/img/edm_414.jpg";
      } else if ( $img.prop("width") <= 540 ){
        this.pic = "assets/img/edm_540.jpg";
      } else if ( $img.prop("width") <= 768 ){
        this.pic = "assets/img/edm_768.jpg";
      } else if ( $img.prop("width") <= 1024 ){
        this.pic = "assets/img/edm_1024.jpg";
      } else {
        this.pic = "assets/img/edm_1366.jpg";
      }
    },
    getNewEdm(){
      var $img = $("#test");
      var pos = 0;
      if ( $img.prop("width") <= 280 ){
        pos = 280;
      } else if ( $img.prop("width") <= 320 ){
        pos = 320;
      } else if ( $img.prop("width") <= 360 ){
        pos = 360;
      }  else if ( $img.prop("width") <= 375 ){
        pos = 375;
      } else if ( $img.prop("width") <= 411 ){
        pos = 411;
      } else if ( $img.prop("width") <= 414 ){
        pos = 414;
      } else if ( $img.prop("width") <= 540 ){
        pos = 540;
      } else if ( $img.prop("width") <= 768 ){
        pos = 768;
      } else if ( $img.prop("width") <= 1024 ){
        pos = 1024;
      } else {
        pos = 1366;
      }
      for (var i = this.edmList.length - 1; i >= 0; i--) {
        if (this.edmList[i].type == ("new_edm"+pos)) {
          if(config && config.old_images && config.old_images=="Y"){             
            this.getImage(this.edmList[i].image_url);
          } else {  
            this.getImage(this.edmList[i].image);
          } 
          break;
        }
      }
    },
    setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
    },
    onBack(){
      if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
        this.$router.push(this.$route.params.back);
      } else {
        this.$router.push({
          name: "Home"
        });
      }
    },
    getImage(image_url){
      if(config && config.old_images && config.old_images=="Y"){             
        this.apiName = "image";
        this.form = [];
        oAC.getAllRecord(oAC.getApiParam(this), {url:image_url, key: 0}).then(imgRes => {
          if(imgRes && imgRes.image ){                        
              this.pic = 'data:image/jpeg;base64, '+ imgRes.image;
          } else {
              this.pic = "";
          }
        }).catch((err)=> {
          this.pic = "";
          oltConsole.log(err);
        });
      } else {  
        this.pic = image_url;
      } 
    },
    getMemberProfile(){
      this.apiName="page_member_profile";
      oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid}).then(res => {
        if (res != undefined && res.user != null) {
          if (res.user[0].ulid != "") {
            this.ulid = res.user[0].ulid;
          }
          this.isCanClick = true;
        }
      }).catch((err)=> {
        oltConsole.log(err);
      });
    },
    getEdmList(){
      this.apiName="page_home_get_edm";
      oAC.getAllRecord(oAC.getApiParam(this), {alid: this.alid}).then(res => {
        if (res != undefined && res.edm_list != null ) {
          this.activity_list = res.activity_list;
          this.edmList = res.edm_list;
          this.orient();
        }
      }).catch((err)=> {
        oltConsole.log(err);
      });
    },
    onBanner(){
      if (this.isVisitor){
        this.openUrl = 'Home';
        this.openLoginAlert(this.openLogin);
      } else if (this.isOld){
        this.$router.push({
          name: 'SpecialUpgradeVip',
          params:{
            back: "Home"
          }
        });
      }
      else if(this.activity_list != undefined && this.activity_list.length > 0){
        var item = this.activity_list[0];

        var hyperlink = item.hyperlink;
        var link_type = item.link_type;
        var bnid = item.bnid;
        if(this.$GlobalData.isDebug){
          oltConsole.log("hyperlink: " + hyperlink);
          oltConsole.log("link_type: " + link_type);
        }
        // 內部連結
        if (link_type == "internal"){
          if(hyperlink != null && hyperlink.length > 0){
            switch(hyperlink){
              // 等級測驗
              case 'level_exam_intro':
                if(this.isVisitor){
                  this.openUrl = 'LevelExamIntro';
                  this.openLoginAlert(this.openLogin);
                } else {
                  this.$router.push({
                    name: 'LevelExamIntro',
                    params: {
                      back: "Home"
                    }
                  });
                }
              break;
              // 升級VIP會員
              case 'upgrade_vip':
                if(this.isVisitor){
                  this.openUrl = 'UpgradeVip';
                  this.openLoginAlert(this.openLogin);
                } else {
                  this.$router.push({
                    name: 'UpgradeVip',
                    params: {
                      backUrl: "Home"
                    }
                  });
                }
              break;
              // 我的
              case 'mine':
                this.$router.push({
                  name: 'Mine',
                  params: {
                    back: "Home"
                  }
                });
              break;
              // 每日學習
              case 'daily_learn':
                if(this.isVisitor){
                  this.openUrl = 'DailyLearn';
                  this.openLoginAlert(this.openLogin);
                } else {
                  this.$router.push({
                    name: 'DailyLearn'
                  });
                }
              break;
              // 深度學習
              case 'deep_learn':
                this.$router.push({
                  name: 'DeepLearn',
                  params: {
                    back: "Home"
                  }
                });
              break;
              // 自訂課程
              case 'my_courses_custom':
                this.onClickCustomize();
              break;
              // EDM升級VIP會員
              case 'edm_upgrade_vip':
                if(this.isVisitor){
                  this.openUrl = 'Home';
                  this.openLoginAlert(this.openLogin);
                } else if (this.ulid === "level4") {
                  this.title = "";
                  this.content = "您已經是訂戶囉！感謝您的支持！";
                  this.btnNameUp = "我知道了";
                  this.$refs.commonAlert1.onShow();
                } else {
                  this.$router.push({
                    name: 'SpecialUpgradeVip',
                    params:{
                      back: "Home"
                    }
                  });
                }
              break;
              // 不跳轉
              case 'none':
              break;
              // 彈窗公告
              case 'special_upgrade_vip':
                if (this.ulid === "level4") {
                  this.title = "";
                  this.content = "您已經是訂戶囉！感謝您的支持！";
                  this.btnNameUp = "我知道了";
                  this.$refs.commonAlert1.onShow();
                } else {
                  this.$router.push({
                    name: 'SpecialUpgradeVip',
                    params:{
                      back: "Home"
                    }
                  });
                }
              break;
              // 推薦好友
              case 'recommend_friend':
                if(this.isVisitor){
                  this.openUrl = 'RecommendFriend';
                  this.openLoginAlert(this.openLogin);
                } else {
                  this.$router.push({
                    name: 'RecommendFriend'
                  });
                }
              break;
            }
          }
        }
        // 外部連結
        else if (link_type == "external"){
          // oltConsole.log(`連結外部網站`);
          this.$JsBridge.callHandler('openVueWeb', hyperlink, this.openVueWebCallback);
        }
      }
    },
    openVueWebCallback(){

    },
    openLoginAlert(funcUp){
        this.title = '完成註冊';
        this.content = '為你留下學習的紀錄';
        this.btnNameUp = '馬上註冊';
        this.btnNameDown = '繼續逛逛';
        this.onFuncUp = funcUp; 
        this.onFuncDown = null;         
        this.$refs.commonAlert2Func.onShow();
    }
  },
  mounted() {
    var _this = this;
    _this.orient();
    window.onresize = function(){ // 定義視窗大小變更通知事件
        _this.orient();
    };
  },
  created(){
    if (this.$route.query && this.$route.query.type != "" && this.$route.query.type != undefined){
      this.isOld = this.$route.query.type == "old";
    } else if (this.$route.params && this.$route.params.type != undefined && this.$route.params.type != "" ){
      this.isOld = this.$route.params.type == "old";
    }

    if (this.$route.query && this.$route.query.alid != undefined && this.$route.query.alid != "" ){
      this.alid = this.$route.query.alid;
    } else if (this.$route.params && this.$route.params.alid != undefined && this.$route.params.alid != "" ){
      this.alid = this.$route.params.alid;
    }

    if (this.$route.query && this.$route.query.uid != undefined && this.$route.query.uid != "" ){
      this.$GlobalData.uid = this.$route.query.uid;
    }

    if (this.$GlobalData.uid == undefined || this.$GlobalData.uid == "") {
      this.isVisitor = true;
    }

    if (this.$JsBridge.isAndroid) {
        this.setOnBack();
    }


    if (!this.isVisitor) {
      this.getMemberProfile();
      this.getEdmList();
    }
  }
};
</script>
<style>
  .bg {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
  }
  .bg img {
    min-height: 100%;
    width: 100%;
  }

  body::-webkit-scrollbar {
      display: none;
  }

  .btn-next{
    background-color: black;
    height:10px;
    position: absolute;
      right: 0;
      left: 0;
    display: flex;
    
      z-index: 15;
      
      justify-content: center;
      padding-left: 0;
      margin-right: 15%;
      margin-left: 15%;
      list-style: none;
  }
  /*隱藏滾動條*/
  body {
      -ms-overflow-style: none;
  }
  /*注意！若只打 hidden，chrome 的其它 hidden 會出問題*/
  html {
      overflow: -moz-hidden-unscrollable; 
      height: 100%;
  }

  body {
    height: 100%;
    overflow: auto;
  }
  .top_header{
    background: white;
      color: white;
      position: fixed;
      top: 0;
      z-index: 1;
      max-width: inherit;
  }
  .bottom_img{
    position: absolute;
      top: 60px;
  }
  @media only screen and (orientation: landscape) and (max-width: 1366px) {
    .toptitle {
      height: 65px;
      white-space: nowrap; }
    .toptitle img {
      margin-top: 16px;
      margin-left: 1vw;
      height: 33px; }
  }

  @media only screen and (max-width: 1024px) {
    .toptitle {
      height: 65px;
      white-space: nowrap; }
    .toptitle img {
      margin-top: 16px;
      margin-left: 2vw;
      height: 33px; }
    .bottom_img{
      top: 65px;
    }
  }

  @media only screen and (orientation: landscape) and (max-width: 1024px) {
    .toptitle {
      height: 65px; }
    .toptitle img {
      margin-top: 19px;
      height: 30px; }
  }
  @media only screen and (max-width: 768px) {
    .toptitle {
      height: 65px; }
    .toptitle img {
      margin-top: 18px;
      height: 30px; 
    margin-left: 4vw;}
  }
  @media only screen and (orientation: landscape) and (max-width: 823px) {
    .toptitle {
      height: 50px; }
    .toptitle img {
      margin-top: 14px;
      height: 25px; }
  }
  @media only screen and (max-width: 480px) {
    .toptitle {
      height: 50px; }
    .toptitle img {
      margin-top: 14px;
      height: 25px; 
    margin-left: 2vw;}
    .bottom_img{
      top: 48px;
    }
  }
  @media only screen and (orientation: landscape) and (max-width: 568px) {
    .toptitle {
      height: 48px; }
    .toptitle img {
      margin-top: 12px;
      height: 25px; }
  }

  @media only screen and (max-width: 414px) {
    .toptitle img {
      margin-left: 2vw; }
  }
  @media only screen and (max-width: 375px) {
    .toptitle img {
      margin-left: 2vw; }
  }


  @media only screen and (max-width: 320px) {
    .toptitle {
      height: 42px; }
    .toptitle img {
      margin-top: 10px;
      height: 25px; }
    .bottom_img{
      top: 40px;
    }
  }
  @media only screen and (max-width: 280px) {
    .toptitle img {
      margin-left: 1vw; }
    .bottom_img{
      top: 38px;
    }
  }

</style>
